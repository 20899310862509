import{Router,useHref,useNavigate,useLocation,useResolvedPath,useBlocker}from'react-router';import{createBrowserHistory,createHashHistory,createPath}from'history';import PropTypes from'prop-types';import{useRef,useReducer,useLayoutEffect,createElement,forwardRef,useCallback,useMemo}from'react';export{MemoryRouter,Navigate,Outlet,Route,Router,Routes,createRoutesFromArray,createRoutesFromChildren,generatePath,matchPath,matchRoutes,resolvePath,useBlocker,useHref,useInRouterContext,useLocation,useMatch,useNavigate,useOutlet,useParams,useResolvedPath,useRoutes}from'react-router';function n(){n=Object.assign||function(a){for(var d=1;d<arguments.length;d++){var b=arguments[d],c;for(c in b)Object.prototype.hasOwnProperty.call(b,c)&&(a[c]=b[c])}return a};return n.apply(this,arguments)}function p(a,d){if(null==a)return{};var b={},c=Object.keys(a),e;for(e=0;e<c.length;e++){var f=c[e];0<=d.indexOf(f)||(b[f]=a[f])}return b}
function q(a,d){if(a){if("string"===typeof a)return r(a,d);var b=Object.prototype.toString.call(a).slice(8,-1);"Object"===b&&a.constructor&&(b=a.constructor.name);if("Map"===b||"Set"===b)return Array.from(b);if("Arguments"===b||/^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(b))return r(a,d)}}function r(a,d){if(null==d||d>a.length)d=a.length;for(var b=0,c=Array(d);b<d;b++)c[b]=a[b];return c}
function t(a){var d=0;if("undefined"===typeof Symbol||null==a[Symbol.iterator]){if(Array.isArray(a)||(a=q(a)))return function(){return d>=a.length?{done:!0}:{done:!1,value:a[d++]}};throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");}d=a[Symbol.iterator]();return d.next.bind(d)}function v(a,d){if(!a){"undefined"!==typeof console&&console.warn(d);try{throw Error(d);}catch(b){}}}
function w(a){var d=a.children;a=a.window;var b=useRef();null==b.current&&(b.current=createBrowserHistory({window:a}));var c=b.current;a=useReducer(function(a,b){return b},{action:c.action,location:c.location});b=a[0];var e=a[1];useLayoutEffect(function(){return c.listen(e)},[c]);return createElement(Router,{children:d,action:b.action,location:b.location,navigator:c})}"production"!==process.env.NODE_ENV&&(w.displayName="BrowserRouter",w.propTypes={children:PropTypes.node,window:PropTypes.object});
function x(a){var d=a.children;a=a.window;var b=useRef();null==b.current&&(b.current=createHashHistory({window:a}));var c=b.current;a=useReducer(function(a,b){return b},{action:c.action,location:c.location});b=a[0];var e=a[1];useLayoutEffect(function(){return c.listen(e)},[c]);return createElement(Router,{children:d,action:b.action,location:b.location,navigator:c})}"production"!==process.env.NODE_ENV&&(x.displayName="HashRouter",x.propTypes={children:PropTypes.node,window:PropTypes.object});
var y=forwardRef(function(a,d){var b=a.onClick,c=a.replace,e=void 0===c?!1:c,f=a.state,g=a.target,m=a.to;a=p(a,["onClick","replace","state","target","to"]);c=useHref(m);var k=useNavigate(),u=useLocation(),h=useResolvedPath(m);return createElement("a",Object.assign({},a,{href:c,onClick:function(a){b&&b(a);a.defaultPrevented||0!==a.button||g&&"_self"!==g||a.metaKey||a.altKey||a.ctrlKey||a.shiftKey||(a.preventDefault(),a=!!e||createPath(u)===createPath(h),k(m,{replace:a,state:f}))},ref:d,target:g}))});
"production"!==process.env.NODE_ENV&&(y.displayName="Link",y.propTypes={onClick:PropTypes.func,replace:PropTypes.bool,state:PropTypes.object,target:PropTypes.string,to:PropTypes.oneOfType([PropTypes.string,PropTypes.shape({pathname:PropTypes.string,search:PropTypes.string,hash:PropTypes.string})]).isRequired});
var z=forwardRef(function(a,d){var b=a["aria-current"],c=void 0===b?"page":b;b=a.activeClassName;var e=void 0===b?"active":b;b=a.activeStyle;var f=a.caseSensitive,g=void 0===f?!1:f;f=a.className;var m=void 0===f?"":f;f=a.end;var k=void 0===f?!1:f,u=a.style;f=a.to;a=p(a,"aria-current activeClassName activeStyle caseSensitive className end style to".split(" "));var h=useLocation(),l=useResolvedPath(f);h=h.pathname;l=l.pathname;g||(h=h.toLowerCase(),l=l.toLowerCase());c=(g=k?h===l:h.startsWith(l))?c:
void 0;e=[m,g?e:null].filter(Boolean).join(" ");b=n({},u,{},g?b:null);return createElement(y,Object.assign({},a,{"aria-current":c,className:e,ref:d,style:b,to:f}))});
"production"!==process.env.NODE_ENV&&(z.displayName="NavLink",z.propTypes=n({},y.propTypes,{"aria-current":PropTypes.oneOf("page step location date time true".split(" ")),activeClassName:PropTypes.string,activeStyle:PropTypes.object,className:PropTypes.string,style:PropTypes.object,to:PropTypes.oneOfType([PropTypes.string,PropTypes.shape({pathname:PropTypes.string,search:PropTypes.string,hash:PropTypes.string})]).isRequired}));function A(a){B(a.message,a.when);return null}
"production"!==process.env.NODE_ENV&&(A.displayName="Prompt",A.propTypes={message:PropTypes.string,when:PropTypes.bool});function B(a,d){void 0===d&&(d=!0);var b=useCallback(function(b){window.confirm(a)&&b.retry()},[a]);useBlocker(b,d)}function C(a){void 0===a&&(a="");return new URLSearchParams("string"===typeof a||Array.isArray(a)||a instanceof URLSearchParams?a:Object.keys(a).reduce(function(d,b){var c=a[b];return d.concat(Array.isArray(c)?c.map(function(a){return[b,a]}):[[b,c]])},[]))}


function useSearchParams(a){"production"!==process.env.NODE_ENV?v("undefined"!==typeof URLSearchParams,"You cannot use the `useSearchParams` hook in a browser that does not support the URLSearchParams API. If you need to support Internet Explorer 11, we recommend you load a polyfill such as https://github.com/ungap/url-search-params\n\nIf you're unsure how to load polyfills, we recommend you check out https://polyfill.io/v3/ which provides some recommendations about how to load polyfills only for users that need them, instead of for every user."):void 0;
var d=useRef(C(a)),b=useLocation();a=useMemo(function(){function a(){var a=k.value;c.has(a)||d.current.getAll(a).forEach(function(b){c.append(a,b)})}for(var c=C(b.search),e=t(d.current.keys()),k;!(k=e()).done;)a();return c},[b.search]);var c=useNavigate(),e=useCallback(function(a,b){c("?"+C(a),b)},[c]);return[a,e]};export{w as BrowserRouter,x as HashRouter,y as Link,z as NavLink,A as Prompt,C as createSearchParams,B as usePrompt,useSearchParams}
