import{createMemoryHistory,Action,parsePath}from'history';import PropTypes from'prop-types';import{createContext,useRef,useReducer,useLayoutEffect,createElement,useContext,useEffect,useMemo,useCallback,Children,isValidElement,Fragment}from'react';function f(){f=Object.assign||function(a){for(var b=1;b<arguments.length;b++){var c=arguments[b],d;for(d in c)Object.prototype.hasOwnProperty.call(c,d)&&(a[d]=c[d])}return a};return f.apply(this,arguments)}var k="production"!==process.env.NODE_ENV?function(a){return Object.freeze(a)}:function(a){return a};function l(a,b){if(!a)throw Error(b);}function m(a,b){if(!a){"undefined"!==typeof console&&console.warn(b);try{throw Error(b);}catch(c){}}}var p={};
function q(a,b,c){b||p[a]||(p[a]=!0,"production"!==process.env.NODE_ENV?m(!1,c):void 0)}var r=createContext({static:!1});"production"!==process.env.NODE_ENV&&(r.displayName="Location");var v=createContext({outlet:null,params:k({}),pathname:"",route:null});"production"!==process.env.NODE_ENV&&(v.displayName="Route");
function w(a){var b=a.children,c=a.initialEntries;a=a.initialIndex;var d=useRef();null==d.current&&(d.current=createMemoryHistory({initialEntries:c,initialIndex:a}));var e=d.current;c=useReducer(function(a,c){return c},{action:e.action,location:e.location});a=c[0];var g=c[1];useLayoutEffect(function(){return e.listen(g)},[e]);return createElement(x,{children:b,action:a.action,location:a.location,navigator:e})}
"production"!==process.env.NODE_ENV&&(w.displayName="MemoryRouter",w.propTypes={children:PropTypes.node,initialEntries:PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string,PropTypes.shape({pathname:PropTypes.string,search:PropTypes.string,hash:PropTypes.string,state:PropTypes.object,key:PropTypes.string})])),initialIndex:PropTypes.number});
function y(a){var b=a.to,c=a.replace,d=a.state;z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"<Navigate> may be used only in the context of a <Router> component."):l(!1);"production"!==process.env.NODE_ENV?m(!useContext(r).static,"<Navigate> must not be used on the initial render in a <StaticRouter>. This is a no-op, but you should modify your code so the <Navigate> is only ever rendered in response to some user interaction or state change."):void 0;var e=A();useEffect(function(){e(b,{replace:c,
state:d})});return null}"production"!==process.env.NODE_ENV&&(y.displayName="Navigate",y.propTypes={to:PropTypes.oneOfType([PropTypes.string,PropTypes.shape({pathname:PropTypes.string,search:PropTypes.string,hash:PropTypes.string})]).isRequired,replace:PropTypes.bool,state:PropTypes.object});function B(){return C()}"production"!==process.env.NODE_ENV&&(B.displayName="Outlet",B.propTypes={});function D(a){a=a.element;return void 0===a?createElement(B,null):a}
"production"!==process.env.NODE_ENV&&(D.displayName="Route",D.propTypes={caseSensitive:PropTypes.bool,children:PropTypes.node,element:PropTypes.element,path:PropTypes.string});
function x(a){var b=a.children;b=void 0===b?null:b;var c=a.action;c=void 0===c?Action.Pop:c;var d=a.location,e=a.navigator;a=a.static;a=void 0===a?!1:a;z()?"production"!==process.env.NODE_ENV?l(!1,"You cannot render a <Router> inside another <Router>. You never need more than one."):l(!1):void 0;return createElement(r.Provider,{children:b,value:{action:c,location:d,navigator:e,static:a}})}
"production"!==process.env.NODE_ENV&&(x.displayName="Router",x.propTypes={children:PropTypes.node,action:PropTypes.oneOf(["POP","PUSH","REPLACE"]),location:PropTypes.object.isRequired,navigator:PropTypes.shape({createHref:PropTypes.func.isRequired,push:PropTypes.func.isRequired,replace:PropTypes.func.isRequired,go:PropTypes.func.isRequired,block:PropTypes.func.isRequired}).isRequired,static:PropTypes.bool});function E(a){var b=a.basename;b=void 0===b?"":b;a=F(a.children);return G(a,b)}
"production"!==process.env.NODE_ENV&&(E.displayName="Routes",E.propTypes={basename:PropTypes.string,children:PropTypes.node});function z(){return null!=useContext(r).location}function H(){z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"useLocation() may be used only in the context of a <Router> component."):l(!1);return useContext(r).location}
function A(){z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"useNavigate() may be used only in the context of a <Router> component."):l(!1);var a=useContext(r).navigator,b=useContext(v).pathname,c=useRef(!1);useEffect(function(){c.current=!0});return useCallback(function(d,e){void 0===e&&(e={});c.current?"number"===typeof d?a.go(d):(d=J(d,b),(e.replace?a.replace:a.push)(d,e.state)):"production"!==process.env.NODE_ENV?m(!1,"You should call navigate() in a useEffect, not when your component is first rendered."):
void 0},[a,b])}function C(){return useContext(v).outlet}function K(a){var b=useContext(v).pathname;return useMemo(function(){return J(a,b)},[a,b])}
function G(a,b){void 0===b&&(b="");var c=useContext(v),d=c.route,e=c.pathname,g=c.params;"production"!==process.env.NODE_ENV&&(c=d&&d.path,q(e,!d||d.path.endsWith("*"),'You rendered descendant <Routes> (or called `useRoutes`) at "'+e+'" (under <Route path="'+(c+'">) but the parent route path has no trailing "*". This means if you navigate deeper, the parent won\'t match anymore and therefore the child routes will never render.\n\nPlease change the parent <Route path="')+(c+'"> to <Route path="'+c+
'/*">.')));b=b?L([e,b]):e;var h=H();return(d=useMemo(function(){return M(a,h,b)},[h,a,b]))?d.reduceRight(function(a,c){var d=c.pathname,e=c.route;return createElement(v.Provider,{children:e.element,value:{outlet:a,params:k(f({},g,{},c.params)),pathname:L([b,d]),route:e}})},null):null}function N(a){return a.map(function(a){var c={path:a.path||"/",caseSensitive:!0===a.caseSensitive,element:a.element||createElement(B,null)};a.children&&(c.children=N(a.children));return c})}
function F(a){var b=[];Children.forEach(a,function(a){if(isValidElement(a))if(a.type===Fragment)b.push.apply(b,F(a.props.children));else{var c={path:a.props.path||"/",caseSensitive:!0===a.props.caseSensitive,element:a};a.props.children&&(a=F(a.props.children),a.length&&(c.children=a));b.push(c)}});return b}
function M(a,b,c){void 0===c&&(c="");"string"===typeof b&&(b=parsePath(b));b=b.pathname||"/";if(c)if(c=c.replace(/^\/*/,"/").replace(/\/+$/,""),b.startsWith(c))b=b===c?"/":b.slice(c.length);else return null;a=O(a);P(a);var d=null;for(c=0;null==d&&c<a.length;++c)a:{d=b;for(var e=a[c][1],g="/",h={},I=[],n=0;n<e.length;++n){var t=e[n],u="/"===g?d:d.slice(g.length)||"/";u=Q({path:t.path,caseSensitive:t.caseSensitive,end:n===e.length-1},u);if(!u){d=null;break a}g=L([g,u.pathname]);h=f({},h,{},u.params);
I.push({route:t,pathname:g,params:k(h)})}d=I}return d}function O(a,b,c,d,e){void 0===b&&(b=[]);void 0===c&&(c="");void 0===d&&(d=[]);void 0===e&&(e=[]);a.forEach(function(a,h){var g=L([c,a.path]),n=d.concat(a);h=e.concat(h);a.children&&O(a.children,b,g,n,h);b.push([g,n,h])});return b}function P(a){var b=a.reduce(function(a,b){b=b[0];a[b]=R(b);return a},{});S(a,function(a,d){var c=a[2];a=b[a[0]];var g=d[2];d=b[d[0]];return a!==d?d-a:T(c,g)})}var U=/^:\w+$/,V=2,W=1,X=10,Y=-2;
function Z(a){return"*"===a}function R(a){a=a.split("/");var b=a.length;a.some(Z)&&(b+=Y);return a.filter(function(a){return!Z(a)}).reduce(function(a,b){return a+(U.test(b)?V:""===b?W:X)},b)}function T(a,b){return a.length===b.length&&a.slice(0,-1).every(function(a,d){return a===b[d]})?a[a.length-1]-b[b.length-1]:0}function S(a,b){var c=a.slice(0);a.sort(function(a,e){return b(a,e)||c.indexOf(a)-c.indexOf(e)})}
function Q(a,b){"string"===typeof a&&(a={path:a});var c=a;a=c.path;var d=c.caseSensitive;c=c.end;c=aa(a,void 0===d?!1:d,void 0===c?!0:c);d=c[1];c=b.match(c[0]);if(!c)return null;b=c[1];var e=c.slice(2);d=d.reduce(function(a,b,c){c=e[c];try{var d=decodeURIComponent(c.replace(/\+/g," "))}catch(t){"production"!==process.env.NODE_ENV?m(!1,'The value for the URL param "'+b+'" will not be decoded because the string "'+(c+'" is a malformed URL segment. This is probably due to a bad percent encoding (')+
(t+").")):void 0,d=c}a[b]=d;return a},{});return{path:a,pathname:b,params:d}}function aa(a,b,c){var d=[],e="^("+a.replace(/^\/*/,"/").replace(/\/?\*?$/,"").replace(/[\\.*+^$?{}|()[\]]/g,"\\$&").replace(/:(\w+)/g,function(a,b){d.push(b);return"([^\\/]+)"})+")";a.endsWith("*")?(a.endsWith("/*")&&(e+="\\/?"),d.push("*"),e+="(.*)"):c&&(e+="\\/?");c&&(e+="$");return[new RegExp(e,b?void 0:"i"),d]}
function J(a,b){void 0===b&&(b="/");var c="string"===typeof a?parsePath(a):a;a=c.pathname;var d=c.search;d=void 0===d?"":d;c=c.hash;c=void 0===c?"":c;return{pathname:a?ba(a,a.startsWith("/")?"/":b):b,search:d,hash:c}}function L(a){return a.join("/").replace(/\/\/+/g,"/")}function ba(a,b){var c=b.replace(/\/+$/,"").replace(/\/\/+/g,"/").split("/");a.replace(/\/\/+/g,"/").split("/").forEach(function(a){".."===a?1<c.length&&c.pop():"."!==a&&c.push(a)});return 1<c.length?L(c):"/"}
function generatePath(a,b){void 0===b&&(b={});return a.replace(/:(\w+)/g,function(a,d){null==b[d]?"production"!==process.env.NODE_ENV?l(!1,'Missing ":'+d+'" param'):l(!1):void 0;return b[d]}).replace(/\/*\*$/,function(){return null==b["*"]?"":b["*"].replace(/^\/*/,"/")})};
function useBlocker(a,b){void 0===b&&(b=!0);z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"useBlocker() may be used only in the context of a <Router> component."):l(!1);var c=useContext(r).navigator;useEffect(function(){if(b){var d=c.block(function(b){var c=f({},b,{retry:function(){d();b.retry()}});a(c)});return d}},[c,a,b])};
function useHref(a){z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"useHref() may be used only in the context of a <Router> component."):l(!1);var b=useContext(r).navigator;a=K(a);return b.createHref(a)};function useMatch(a){z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"useMatch() may be used only in the context of a <Router> component."):l(!1);var b=H();return Q(a,b.pathname)};
function useParams(){return useContext(v).params};function useRoutes(a,b){void 0===b&&(b="");z()?void 0:"production"!==process.env.NODE_ENV?l(!1,"useRoutes() may be used only in the context of a <Router> component."):l(!1);var c=useMemo(function(){return N(a)},[a]);return G(c,b)};export{w as MemoryRouter,y as Navigate,B as Outlet,D as Route,x as Router,E as Routes,N as createRoutesFromArray,F as createRoutesFromChildren,generatePath,Q as matchPath,M as matchRoutes,J as resolvePath,useBlocker,useHref,z as useInRouterContext,H as useLocation,useMatch,A as useNavigate,C as useOutlet,useParams,K as useResolvedPath,useRoutes}
